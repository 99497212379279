import DatePicker from 'vue2-datepicker';
import TdsSection from '../../../taxation/taxSection';
export default {
  name: 'addTwoSixASInvoice',
  components: { DatePicker, TdsSection },
  props: ['invoiceDetails'],
  data() {
    return {
      loader: false,
      editMode: false,
      openTdsSectionModal: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      trx_number: null,
      trx_type: null,
      trx_date: null,
      customer_number: null,
      customer_name: null,
      bill_to_site: null,
      bill_to_address: null,
      ship_to_site: null,
      ship_to_address: null,
      term_name: null,
      term_due_date: null,
      comments: null,
      exchange_rate_type: null,
      exchange_rate: null,
      exchange_date: null,
      invoice_amount: null,
      taxable_amount: null,
      adjustment_amount: null,
      cgst_amount: null,
      sgst_amount: null,
      ugst_amount: null,
      igst_amount: null,
      applied_amount: null,
      complete_flag: null,
      irn: null,
      ack_number: null,
      ack_date: null,
      created_by: null,
      creation_date: null,
      invoiceDetailData: [],
      invoiceDetailFields: [
        {
          key: 'line_number'
        },
        {
          key: 'line_type'
        },
        {
          key: 'inventory_item'
        },
        {
          key: 'description'
        },
        {
          key: 'uom_code',
          label: 'UOM Code'
        },
        {
          key: 'quantity'
        },
        {
          key: 'unit_selling_price'
        },
        {
          key: 'amount'
        },
        {
          key: 'taxable_amount'
        },
        {
          key: 'line_recoverable'
        },
        {
          key: 'tax_recoverable'
        }
      ],
      chargeInvoiceData: [
        {
          //   trx_type: null,
          //   tan_number: null,
          tds_section: null,
          tds_section_id: null
          //   tds_rate: null,
          //   ltds_cert_id: null,
          //   amount: null,
          //   status: null,
          //   message: null,
          //   creation_date: null,
          //   created_by: null,
          //   last_update_date: null,
          //   last_updated_by: null
        }
      ],
      chargeInvoiceFields: [
        {
          key: 'trx_type',
          label: 'Transaction Type'
        },
        {
          key: 'tan_number',
          label: 'TAN No.'
        },
        {
          key: 'tds_section',
          label: 'TDS Section'
        },
        {
          key: 'tds_rate',
          label: 'TDS Rate'
        },
        {
          key: 'ltds_cert_id',
          label: 'LTDS Cert. ID'
        },
        {
          key: 'amount'
        },
        {
          key: 'status'
        },
        {
          key: 'ebs_status',
          label: 'ERP Status'
        },
        {
          key: 'erp_trx_id',
          label: 'ERP Trx Id'
        },
        {
          key: 'error_msg'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        }
      ],
      isViewMore: false
    };
  },
  mounted() {
    this.fillRecordsForInvoice();
    this.getTwoSixASInvoiceDetailsById(this.invoiceDetails.customer_trx_id);
    this.getTwoSixASChargeDetailsById(this.invoiceDetails.customer_trx_id);
  },
  methods: {
    fillRecordsForInvoice() {
      // To Fill Records From Master Form
      this.trx_number = this.invoiceDetails.trx_number;
      this.trx_type = this.invoiceDetails.trx_type;
      this.trx_date = this.invoiceDetails.trx_date;
      this.customer_number = this.invoiceDetails.customer_number;
      this.customer_name = this.invoiceDetails.customer_name;
      this.bill_to_site = this.invoiceDetails.bill_to_site;
      this.bill_to_address = this.invoiceDetails.bill_to_address;
      this.ship_to_site = this.invoiceDetails.ship_to_site;
      this.ship_to_address = this.invoiceDetails.ship_to_address;
      this.term_name = this.invoiceDetails.term_name;
      this.term_due_date = this.invoiceDetails.term_due_date;
      this.comments = this.invoiceDetails.comments;
      this.exchange_rate_type = this.invoiceDetails.exchange_rate_type;
      this.exchange_rate = this.invoiceDetails.exchange_rate;
      this.exchange_date = this.invoiceDetails.exchange_date;
      this.invoice_amount = this.invoiceDetails.invoice_amount;
      this.taxable_amount = this.invoiceDetails.taxable_amount;
      this.adjustment_amount = this.invoiceDetails.adjustment_amount;
      this.cgst_amount = this.invoiceDetails.cgst_amount;
      this.sgst_amount = this.invoiceDetails.sgst_amount;
      this.ugst_amount = this.invoiceDetails.ugst_amount;
      this.igst_amount = this.invoiceDetails.igst_amount;
      this.applied_amount = this.invoiceDetails.applied_amount;
      this.complete_flag =
        this.invoiceDetails.complete_flag === 'Y' ? 'Yes' : 'No';
      this.irn = this.invoiceDetails.irn;
      this.ack_number = this.invoiceDetails.ack_number;
      this.ack_date = this.invoiceDetails.ack_date;
      this.created_by = this.invoiceDetails.created_by;
      this.creation_date = this.invoiceDetails.creation_date;
    },
    getTwoSixASInvoiceDetailsById(customerTrxNumber) {
      // To Get Invoice Details By ID
      this.loader = true;
      this.$store
        .dispatch(
          'twoSixASUpload/getTwoSixASInvoiceDetailsById',
          customerTrxNumber
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.invoiceDetailData = response.data.data.page;
            // this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },

    openCloseTdsSection(flag, index) {
      this.openTdsSectionModal = flag;
      this.tdsIndex = index;
    },
    selectedSectionDetails(items) {
      this.chargeInvoiceData[this.tdsIndex].tds_section = items.section;
      this.chargeInvoiceData[this.tdsIndex].tds_section_id =
        items.tds_section_id;
      this.openTdsSectionModal = false;
    },
    getTwoSixASChargeDetailsById(customerTrxNumber) {
      // To Get Charge Invoice Details By ID
      this.loader = true;
      this.$store
        .dispatch(
          'twoSixASUpload/getTwoSixASChargeDetailsById',
          customerTrxNumber
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.chargeInvoiceData = response.data.data.page;
            // this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
