import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import addTwoSixASInvoices from '../twoSixASInvoices/addTwoSixASInvoices';
export default {
  name: 'twoSixASInvoices',
  components: { DatePicker, addTwoSixASInvoices },
  watch: {
    currentPage: function() {
      this.getTwoSixInvoices();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTwoSixInvoices();
    }
  },
  data() {
    return {
      showTdsInvoiceModal: false,
      invoiceDetails: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      loader: false,
      editMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      custName: {
        value: null,
        text: null
      },
      custNumber: {
        value: null,
        text: null
      },
      trxNumber: {
        value: null,
        text: null
      },
      requestNumber: {
        value: null,
        text: null
      },
      from_date: null,
      to_date: null,
      invoiceData: [],
      invoiceFields: [
        {
          key: 'trx_number',
          label: 'Transaction No.'
        },
        {
          key: 'trx_type',
          label: 'Transaction Type'
        },
        {
          key: 'trx_date',
          label: 'Transaction Date'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'customer_number',
          label: 'Customer No.'
        },
        {
          key: 'bill_to_site'
        },
        {
          key: 'bill_to_address'
        },
        {
          key: 'ship_to_site'
        },
        {
          key: 'ship_to_address'
        },
        {
          key: 'term_name'
        },
        {
          key: 'term_due_date'
        },
        {
          key: 'comments'
        },
        {
          key: 'exchange_rate_type'
        },
        {
          key: 'exchange_rate'
        },
        {
          key: 'exchange_date'
        },
        {
          key: 'invoice_amount'
        },
        {
          key: 'taxable_amount'
        },
        {
          key: 'adjustment_amount'
        },
        {
          key: 'cgst_amount',
          label: 'CGST Amount'
        },
        {
          key: 'sgst_amount',
          label: 'SGST Amount'
        },
        {
          key: 'ugst_amount',
          label: 'UGST Amount'
        },
        {
          key: 'igst_amount',
          label: 'IGST Amount'
        },
        {
          key: 'applied_amount'
        },
        {
          key: 'complete_flag'
        },
        {
          key: 'invoice_currency_code',
          label: 'Currency Code'
        },
        {
          key: 'request_number'
        },
        {
          key: 'irn',
          label: 'Invoice Reference No.'
        },
        {
          key: 'ack_number',
          label: 'Ack. Number'
        },
        {
          key: 'ack_date',
          label: 'Ack. Date'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        }
      ]
    };
  },
  mounted() {
    // customer_trx_id
    this.getTwoSixInvoices();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          // this.showTdsInvoiceModal = true;
        }
        if (actionName === 'download' && !this.showTdsInvoiceModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'twoSixASUpload/getTwoSixASInvoiceList',
            'invoice download',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getTwoSixInvoices() {
      // Get API for 26AS Invoices
      const payload = {
        customerName: this.custName.text,
        customerNumber: this.custNumber.text,
        invoiceNumber: this.trxNumber.text,
        requestNumber: this.requestNumber.text,
        fromDate: this.from_date,
        toDate: this.to_date
      };
      this.loader = true;
      this.$store
        .dispatch('twoSixASUpload/getTwoSixASInvoiceList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.invoiceData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideTdsInvoiceModal(flag) {
      this.showTdsInvoiceModal = flag;
    },
    rowSelected(item) {
      this.invoiceDetails = item;
      this.showTdsInvoiceModal = true;
    },
    clearFilters() {
      // Clear Search Filters
      this.custName.text = null;
      this.custName.value = null;
      this.custNumber.text = null;
      this.custNumber.value = null;
      this.trxNumber.text = null;
      this.trxNumber.value = null;
      this.requestNumber.text = null;
      this.requestNumber.value = null;
      this.from_date = null;
      this.to_date = null;
      this.getTwoSixInvoices();
    }
  }
};
